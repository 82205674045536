import Cookies from 'js-cookie';
import get from '../helpers/data_layer/get';
import flashMessage from '../apps/flash_message/flash_message';
import nav from '../components/nav/index';
import overlayFocus from '../components/overlay_focus';
import profileLoader from '../components/profiles/profile_loader';
// Until we refactor flashmessage we need to import styles to avoid duplication when bundling
import '../../sass/apps/flash_message.scss';
import '../../sass/components/site_wide_promo_banner.scss';
import commerceTracking from '../apps/commerce/tracking/classic/index';
import facebookConnect from '../components/facebook_connect';
import trackRegistration from '../components/tracking/registered';
import trackReEngagement from '../components/tracking/reEngaged';
import messageNotification from '../services/message_notification/message_notification';
import { fetchData } from '../helpers/base_data_fetcher';
import processLocalGtmQueue from '../helpers/gtm/processLocalGtmQueue';
import setUpSearchBoxWithAutocomplete from '../helpers/search_box_helpers';
import { logActiveTests } from '../experiments/helpers/ab_tests';
import {
  trackingMainNav,
  trackingSubNav,
} from '../components/tracking/header.js';
import { checkTimezone } from '../helpers/check_user_timezone';
import siteChoice from '../services/site_choice';
import cookie_consent from '../apps/cookie_consent';
import setCookieAndDismissBanner from '../components/site_wide_promo_banner';

const country = get('site.country');
const features = get('config.features');
if (features.home_baked_cookie_consent) {
  cookie_consent.init({ opt_out: country == 'US' });
}
siteChoice();
facebookConnect();
profileLoader.init();
overlayFocus.init();
nav.init();
flashMessage.init();
messageNotification.init();
checkTimezone();
setCookieAndDismissBanner('verification_banner_dismissed', 7);

const localeChoice = Cookies.get('country_id');
if (localeChoice) {
  Cookies.set('country_id', localeChoice, { expires: 30 });
}

trackRegistration();
trackReEngagement();
commerceTracking();
processLocalGtmQueue();

// check for time_arrived_stamp cookie. If not set, then set it now
fetchData('site.domain').then((domain) => {
  const timeArrivedStamp = Cookies.get('time_arrived_stamp');
  if (!timeArrivedStamp) {
    Cookies.set('time_arrived_stamp', Date.now(), { domain, expires: 2 });
  }
});
trackingMainNav();
trackingSubNav();
logActiveTests();

const searchForm = document.querySelectorAll('[data-search-form]');

searchForm.forEach((form) => {
  const searchField = form.querySelector('#search_by_location_field');

  if (searchField) {
    setUpSearchBoxWithAutocomplete(searchField, form);
  }
});

console.log('trigger workflow 21');
